import { useQuery } from 'react-query';
import styles from './editor.module.scss';
import { Project } from '../../types/project';
import { useContext, useMemo } from 'react';
import Properties, { PropertiesTab } from './properties';
import Toolbox from './toolbox';
import { DocumentVideo32, Launch16, Template32, TextCreation32 } from '@carbon/icons-react';
import EditorShell from './editor-shell';
import Timeline from '../timeline/timeline';
import { useInterval } from 'react-use';
import { Button, Text, TextInput, useMantineColorScheme } from '@mantine/core';
import { SpecActionType } from '../../core/reducer';
import { EditorPageAction } from './reducer/ui';
import { EditorPageContext } from '.';
import Match from '../match';
import Player from '../player';
import { getTimelineScenes, getVideoListFromSourceList } from './utils';
import EditorPageToolbox from './toolbox';
import ObjectProperties from './object-properties';

export interface EditorPageProps {
    project: Project,
}

export default function EditorPageInner(props: EditorPageProps) {
    const { colorScheme } = useMantineColorScheme();

    const context = useContext(EditorPageContext);

    const hasScenes = !!context.state.spec.scenes?.length;

    const currentSceneSpec = context.state.spec.scenes[context.state.ui.selectedSceneIndex];
    const currentSceneBaseSourceID = currentSceneSpec?.baseSourceID;
    const currentSceneBaseSource = context.sources[currentSceneBaseSourceID];

    // useInterval(() => {
    //     const timeline = document.querySelector("#timeline") as HTMLDivElement;
    //     const container = document.querySelector(".innerContainer") as HTMLDivElement;
    //     if (timeline && container) {
    //         container.style.maxHeight = `calc(100vh - 4rem - ${timeline.offsetHeight}px)`;
    //     }
    // }, 50);

    const videos = useMemo(() => getVideoListFromSourceList(context),
        [context.state.spec.modifiedAt, Object.keys(context.sources).length]);
    const timelineScenes = getTimelineScenes(context);

    // console.log("videos", videos);
    // console.log(timelineScenes);

    return (
        <EditorShell className={styles.container}>
            <div className={styles.sidebar}>
                <EditorPageToolbox />
                <Properties key={context.state.ui.selectedObject?.objectID}>
                    <ObjectProperties />  
                </Properties>
            </div>
            <div className={styles.player}>
                {hasScenes && <Player videos={videos} 
                    spec={context.state.spec} 
                    selectedObject={context.state.ui.selectedObject} 
                    currentTime={context.state.ui.currentTime} 
                    seek={context.state.ui.seek} 
                    dispatch={context.dispatch} />}
                {!hasScenes && <div className={styles.empty}>
                    <Text>Click the "Add Clip" button to get started.</Text>
                    {context.exports?.length > 0 && JSON.stringify(context.exports)}
                </div>}
            </div>
            <Timeline theme={colorScheme}
                className={styles.timeline}
                dispatch={context.dispatch}
                sources={context.sources}
                scenes={context.state.spec.scenes}
                currentTime={context.state.ui.currentTime}
                selectedObject={context.state.ui.selectedObject} />
        </EditorShell>
    );
}