import { ArrowLeft16, Moon16 } from '@carbon/icons-react';
import { Button, Menu, Divider, Text, useMantineColorScheme, TextInput } from '@mantine/core';
import { AppShell, Affix, Burger, Header, MediaQuery, Navbar, useMantineTheme, ActionIcon, Notification } from '@mantine/core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

export default function AccountPageShell(props: any) {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const navigate = useNavigate();
    
    const header = useMemo(() => (
        <Header height={60} padding="xs">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ActionIcon size="lg" variant="hover" onClick={() => navigate("/")}>
                    <ArrowLeft16 />
                </ActionIcon>
                <h1 onClick={() => navigate("/")}><Text>Return to streamloops app</Text></h1>
                <div style={{ display: 'flex', alignItems: 'center', flexGrow: '1' }}>
                </div>
                <ActionIcon size="lg" variant="default" onClick={() => toggleColorScheme()}>
                    <Moon16 />
                </ActionIcon>
            </div>
        </Header>
    ), [colorScheme]);

    // const navbar = useMemo(() => (
    //     <Navbar width={{ base: 300 }} padding="xs">
    //         {/* Navbar content */}
    //     </Navbar>
    // ), []);

    return <AppShell
        padding="md"
        styles={(theme) => ({
            main: { 
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                '--border-color': theme.colorScheme === 'dark' ? '#333333' : '#cccccc',
            },
        })}
        header={header}>
        {props.children}
    </AppShell>
}