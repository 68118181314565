import { Action } from "../../../core/reducer";
import { TimelineCurrentTime } from "../../timeline/types";
import { PropertiesTab } from "../properties";

export enum EditorPageAction {
    SetTab = "ui:set-tab",
    SetSceneIndex = "ui:set-scene-index",
    SetCurrentTime = "ui:set-current-time",
    SetSelectedObjectID = "ui:set-selected-object-id",
    OpenDialog = "ui:open-dialog",
    CloseDialog = "ui:close-dialog",
}

export interface EditorPageState {
    tab: PropertiesTab,
    dialogState: {
        export: boolean,
    },
    selectedSceneIndex: number, // TODO remove
    selectedObjectID?: string, // TODO remove
    selectedObject?: {
        sceneID: string,
        objectID: string,
    },
    currentTime?: TimelineCurrentTime,
    seek?: TimelineCurrentTime,
}

export const defaultUIState: EditorPageState = {
    tab: PropertiesTab.ClipDetails,
    selectedSceneIndex: 0,
    dialogState: {
        export: false,
    },
};

export function editorPageStateReducer(previous: EditorPageState, action: Action<EditorPageAction>) {
    switch (action.type) {
        case EditorPageAction.SetTab:
            return { ...previous, tab: action.value };
        case EditorPageAction.SetCurrentTime:
            return { 
                ...previous,
                currentTime: action.value,
            };
        case EditorPageAction.SetSceneIndex:
            return { 
                ...previous, 
                selectedSceneIndex: action.value,
                selectedObjectID: undefined,
            };
        case EditorPageAction.SetSelectedObjectID:
            return { 
                ...previous, 
                selectedObjectID: action.value,
            };
        case EditorPageAction.OpenDialog:
            const newDialogState: any = {};
            for (const key of Object.keys(previous.dialogState)) {
                newDialogState[key] = false;
            }
            newDialogState[action.value] = true;
            return { 
                ...previous, 
                dialogState: newDialogState,
            };
        case EditorPageAction.CloseDialog:
            const newDialogState2: any = {};
            for (const key of Object.keys(previous.dialogState)) {
                newDialogState2[key] = false;
            }
            return { 
                ...previous, 
                dialogState: newDialogState2,
            };
    }
}