import Canvas from "./canvas";
import FPSClock from "./fps";
import VideoManagerPortal from "./portal";
// import { render } from "./render";
import Renderer from "./renderer";
import { ExpectedVideoState, OnFrameCallback, Video, VideoManagerState } from "./types";
import { parseVideoTime, sum } from "./utils";

export default class VideoManager2 {
    private videos: Video[] = [];
    private initialized = false;
    private destroyed: boolean = false;
    private state: VideoManagerState = {
        playing: false,
        wasPlayingBefore: false,
        videosChanged: true,
        primaryVideoEnded: false,
        currentVideoIndex: 0,
        seeking: false,
        renderTransitions: false,
    };
    private portal = new VideoManagerPortal({
        onPrimaryVideoEnded: () => this.state.primaryVideoEnded = true,
    });
    private canvas: Canvas | undefined;
    private renderer: Renderer | undefined;
    private timer: any;
    private fpsClock = new FPSClock();

    constructor(renderTransitions: boolean, public onFrame?: OnFrameCallback, public onBeforeFrame?: OnFrameCallback) {
        this.state.renderTransitions = renderTransitions;
    }

    private setRenderDimensions(width: number, height: number) {
        if (this.canvas && this.renderer && this.canvas.width === width 
                && this.canvas.height === height) {
            return;
        }

        this.renderer?.destroy();
        this.canvas?.destroy();

        this.canvas = new Canvas(width, height);
        this.renderer = new Renderer(this.canvas);

        // this.canvas.element.style.display = 'block';
    }

    public destroy() {
        this.destroyed = true;
        clearTimeout(this.timer);
        this.renderer?.destroy();
        this.renderer = undefined;
        this.canvas?.destroy();
        this.canvas = undefined;
        this.portal.destroy();
        this.videos = [];
    }

    public setVideos(videos: Video[]): void {
        if (!this.destroyed) {
            console.log("Loading new videos:", videos);
            this.videos = videos;
            this.state.videosChanged = true;
        }
    }

    public togglePlayState() {
        if (this.state.playing) {
            this.pause();
        } else {
            this.play();
        }
    }

    public play() {
        if (this.destroyed) {
            return;
        }
        this.state.playing = true;
        if (!this.initialized) {
            this.initialized = true;
            console.log("Starting video manager event loop.");
            setTimeout(() => this.tick(), 1);
        }
    }

    public pause() {
        if (this.destroyed) {
            return;
        }
        this.state.playing = false;
    }

    public seek(time: number) {
        if (this.destroyed) {
            return;
        }

        if (time < 0) {
            time = 0;
        } else if (time > this.duration()) {
            time = this.duration();
        }

        this.state.seeking = true;
        this.state.seekTime = time;
    }

    public seekToVideo(videoID: string, videoTime: number) {
        if (this.destroyed) {
            return;
        }

        let time = 0;
        for (const video of this.videos) {
            if (video.id === videoID) {
                time += videoTime;
                break;
            }
            time += video.duration;
        }

        this.seek(time);
    }

    public seekToStart() {
        this.seek(0);
    }

    public seekToEnd() {
        this.seek(this.duration());
    }

    public seekBack(seconds: number) {
        this.seek(this.currentTime() - seconds);
    }

    public seekForward(seconds: number) {
        this.seek(this.currentTime() + seconds);
    }

    async tick() {
        clearTimeout(this.timer);

        if (this.destroyed) {
            return;
        }

        const state = { ...this.state };

        this.state = {
            playing: state.playing,
            wasPlayingBefore: state.wasPlayingBefore,
            videosChanged: false,
            primaryVideoEnded: false,
            currentVideoIndex: state.currentVideoIndex,
            seeking: false,
            renderTransitions: state.renderTransitions,
        };

        if (state.videosChanged) {
            await this.handleNewVideos();
        } else if (state.primaryVideoEnded) {
            await this.handlePrimaryVideoEnded();
        } else if (state.seeking) {
            await this.handleSeek(state.seekTime ?? 0);
        }

        const shouldInvokeCallbacks = this.state.playing || state.seeking
            || state.playing !== state.wasPlayingBefore;

        if (state.playing !== state.wasPlayingBefore && !this.destroyed) {
            await this.handlePlayStateChange();
            this.state.wasPlayingBefore = this.state.playing;
        }

        if (this.portal.primary() && !this.destroyed) {
            this.setRenderDimensions(
                this.portal.primary()?.videoWidth || 100,
                this.portal.primary()?.videoHeight || 100
            );
        }

        if (this.videos.length && !this.destroyed && this.renderer) {
            const expectedVideoState = this.computeExpectedVideoState();
            await this.syncVideoState(expectedVideoState);

            this.renderer.prepareFrames(this.portal.primary(), this.portal.secondary());

            if (shouldInvokeCallbacks) {
                const currentVideo = this.videos[this.state.currentVideoIndex];
                const currentVideoElem = this.portal.primary();
                this.onBeforeFrame?.(this.renderer.primaryCanvas.element, this.currentTime(), currentVideo,
                    currentVideoElem?.currentTime ?? 0, this.state.playing);

                if (expectedVideoState.next.playing) {
                    const nextVideo = this.videos[this.state.currentVideoIndex + 1];
                    const nextVideoElem = this.portal.secondary();
                    this.onBeforeFrame?.(this.renderer.secondaryCanvas.element, this.currentTime(), nextVideo,
                        nextVideoElem?.currentTime ?? 0, this.state.playing);
                }

                if (expectedVideoState.transition && expectedVideoState.transitionProgress > 0) {
                    this.renderer.renderTransition("cube", expectedVideoState.transitionProgress,
                        512, 256, { param1: 42.0 })
                } else {
                    this.renderer.renderSimple();
                }

                this.onFrame?.(this.canvas.element, this.currentTime(), currentVideo, 
                    currentVideoElem?.currentTime ?? 0, this.state.playing)
                this.fpsClock.onFrame();
            }
        }

        if (!this.destroyed) {
            this.timer = setTimeout(() => this.tick(), 10);
        }
    }

    private async handleNewVideos() {
        console.log(`Playlist Update: ${this.videos.length} videos, ${this.duration()} seconds`);
        this.state.currentVideoIndex = 0;
        await this.syncPortal(0);
        console.log("Done")
    }

    private async handlePrimaryVideoEnded() {
        console.log(`Primary video ended.`);
        const nextVideoIndex = this.state.currentVideoIndex + 1;
        if (nextVideoIndex >= this.videos.length) {
            this.state.playing = false;
        } else {
            this.state.currentVideoIndex = nextVideoIndex;
            await this.syncPortal(nextVideoIndex);
        }
    }

    private async handlePlayStateChange() {
        console.log(this.state.playing ? "Playing." : "Pausing.");
        if (!this.state.playing) {
            this.fpsClock.onPause();
        }
    }

    private async handleSeek(time: number) {
        const { index, currentTime } = parseVideoTime(time, this.videos);
        console.log(`Requested seek to ${time}s, seeking to ${currentTime}s in video ${index}.`);
        this.state.currentVideoIndex = index;
        await this.syncPortal(index);
        
        const primary = this.portal.primary();
        if (primary) { 
            primary.currentTime = currentTime; 
        }
    }

    private async syncPortal(currentVideoIndex: number) {
        const currentVideo = this.videos[currentVideoIndex];
        const nextVideo = this.videos[currentVideoIndex + 1];

        await this.portal.setVideos({
            primary: currentVideo,
            secondary: nextVideo, // might be undefined
        });
    }

    private async syncVideoState(expectedVideoState: ExpectedVideoState) {
        const currentVideoElem = this.portal.primary();
        const nextVideoElem = this.portal.secondary();

        if (nextVideoElem && nextVideoElem.readyState >= 1) {
            if (nextVideoElem.paused && nextVideoElem.currentTime !== expectedVideoState.next.currentTime) {
                console.log("Forcing next video to time = " + expectedVideoState.next.currentTime);
                nextVideoElem.currentTime = expectedVideoState.next.currentTime;
            }
        }

        if (this.isExpectedVideoStatePossible(expectedVideoState)) {
            if (expectedVideoState.current.playing && currentVideoElem?.paused) {
                console.log("Playing current video.");
                await this.portal.playPrimary();
            } else if (!expectedVideoState.current.playing && !currentVideoElem?.paused) {
                console.log("Pausing current video.");
                await this.portal.pausePrimary();
            }

            if (expectedVideoState.next.playing && nextVideoElem?.paused) {
                console.log("Playing next video from " + expectedVideoState.next.currentTime);
                await this.portal.playSecondary();
            } else if (!expectedVideoState.next.playing && nextVideoElem && !nextVideoElem.paused) {
                console.log("Pausing next video.");
                await this.portal.pauseSecondary();
            }
        } else {
            console.log("Waiting for videos (pausing).");
            await this.portal.pauseAll();
        }
    }

    private computeExpectedVideoState(): ExpectedVideoState {
        const currentVideo = this.videos[this.state.currentVideoIndex];
        const currentVideoElem = this.portal.primary() as HTMLVideoElement;

        if (!currentVideo || !currentVideoElem) {
            throw "Unexpected video state (1)";
        }

        const TRANSITION_DURATION = 1;

        const timeRemainingInCurrentVideo = currentVideo.duration -
            currentVideoElem.currentTime;

        const isLastVideo = this.state.currentVideoIndex + 1 >= this.videos.length;

        let transition = false;
        let transitionTimeElapsed = 0;
        let transitionProgress = 0;

        if (this.state.renderTransitions) {
            // TODO can implement more complex logic for other transition configurations
            transition = !isLastVideo && timeRemainingInCurrentVideo < TRANSITION_DURATION;
            transitionTimeElapsed = Math.max(0, TRANSITION_DURATION - timeRemainingInCurrentVideo);
            transitionProgress = transitionTimeElapsed / TRANSITION_DURATION;
        }

        if (!this.state.playing) {
            return {
                transition,
                transitionProgress,
                current: {
                    playing: false,
                    currentTime: currentVideoElem.currentTime,
                },
                next: {
                    playing: false,
                    currentTime: transitionTimeElapsed,
                },
            };
        }

        return {
            transition,
            transitionProgress,
            current: {
                playing: true,
                currentTime: currentVideoElem.currentTime,
            },
            next: {
                playing: transition,
                currentTime: transition ? TRANSITION_DURATION - timeRemainingInCurrentVideo : 0,
            },
        };
    }

    private isExpectedVideoStatePossible(state: ExpectedVideoState) {
        const currentVideoElem = this.portal.primary();
        const nextVideoElem = this.portal.secondary();

        const currentReady = currentVideoElem && currentVideoElem.readyState >= 3;

        if (state.current.playing && !currentReady) {
            return false;
        }

        const nextReady = nextVideoElem && nextVideoElem.readyState >= 3;

        if (state.next.playing && !nextReady) {
            console.log("next", nextVideoElem, nextVideoElem?.readyState, nextVideoElem?.currentTime);
            return false;
        }

        return true;
    }

    public duration() {
        const durations = this.videos.map(v => v.duration);
        return sum(durations);
    }

    public currentTime() {
        const durations = this.videos.slice(0, this.state.currentVideoIndex).map(v => v.duration);
        return sum(durations) + (this.portal.primary()?.currentTime ?? 0);
    }

    public playing() {
        return this.state.playing;
    }
}