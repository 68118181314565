import transitions from "gl-transitions";
import createTransition from "gl-transition";

import Canvas, { CanvasType } from "./canvas";

export default class Renderer {
    public primaryCanvas: Canvas;
    public secondaryCanvas: Canvas;
    private glCanvas: Canvas;

    private transitionCache: Record<string, any> = {};

    constructor(private readonly canvas: Canvas) {
        this.primaryCanvas = new Canvas(canvas.width, canvas.height);
        this.secondaryCanvas = new Canvas(canvas.width, canvas.height);
        this.glCanvas = new Canvas(canvas.width, canvas.height, CanvasType.WebGL);
    }

    public prepareFrames(primary?: HTMLVideoElement, secondary?: HTMLVideoElement) {
        if (primary) {
            this.primaryCanvas.draw(primary);
        }

        if (secondary) {
            this.secondaryCanvas.draw(secondary);
        }
    }

    public renderSimple() {
        this.canvas.draw(this.primaryCanvas.element);
    }

    public renderTransition(transitionName: string, progress: number, ...args: any[]) {
        if (!this.transitionCache[transitionName]) {
            const gl = this.glCanvas.getGLContext();
            this.transitionCache[transitionName] = createTransition(gl, 
                transitions.find((t: any) => t.name === transitionName));
        }

        const transition = this.transitionCache[transitionName];

        this.glCanvas.drawTransition(transition, progress as number, 
            this.primaryCanvas.element, this.secondaryCanvas.element, args);

        this.canvas.draw(this.glCanvas.element);
    }

    public destroy() {
        this.primaryCanvas.destroy();
        this.secondaryCanvas.destroy();
        this.glCanvas.destroy();
    }
}