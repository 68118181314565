import {useQuery} from "react-query";

import * as API from './api';

function isDevMode() {
    return true;
}

export function useSession(): any {
    const loader = async () => {
        try {
            const result = await API.get("/session", { r: Date.now() });
            if (!result.userID) {
                window.location.href = "/oauth2/sign_in";
                throw "Unauthorized";
            }
            return result;
        } catch (e) {
            window.location.href = "/oauth2/sign_in";
            throw "Unauthorized";
        }

        
        // return {
        //     user: {
        //         id: "test",
        //         name: "John Doe",
        //     },
        //     userID: "test",
        // };

        // if (isDevMode()) {
            // return process.env.NEXT_PUBLIC_SIGNED_IN === 'true' ? mockSignedInSession : mockSignedOutSession;
        // } else {
            // return API.get(DataType.JSON, "/session");
        // }
    };

    const result = useQuery<any>(["session"], loader, {
        enabled: true,
        staleTime: 60 * 1000,
        refetchInterval: 60 * 1000,
    });

    return [result.data, result.isLoading, signOut];
}

export function signOut() {
    if (isDevMode()) {
        return;
    }

    // const localLogOutURL = "https://" + process.env.NEXT_PUBLIC_DOMAIN + "/oauth2/sign_out";

    // const oauthLogOutURL = "https://" + process.env.NEXT_PUBLIC_OAUTH_DOMAIN + "/auth/realms/" + process.env.NEXT_PUBLIC_OAUTH_REALM
    //     + "/protocol/openid-connect/logout";

    // window.location.href = oauthLogOutURL + "?redirect_uri="
    //     + encodeURIComponent(localLogOutURL);
}