import { ProjectSpec } from "../types/spec";
import * as uuid from 'uuid';

function getIndexByID(array: {id: string}[], id: string) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
            return i;
        }
    }
    return -1;
}

export interface Action<T> {
    type: T,
    value?: any,
}

export const SpecActionType = {
    NoOp: "spec:no-op",
    Load: "spec:load",
    SetProjectTitle: "spec:set-project-title",
    AddScene: "spec:add-scene",
    DeleteScene: "spec:delete-scene",
    SetSceneTitle: "spec:set-scene-title",
    SetSceneOrder: "spec:set-scene-order",
    AddText: "spec:add-text",
    AddCaption: "spec:add-caption",
    SetObjectCoordinates: "spec:set-object-coordinates",
    SetObjectText: "spec:set-object-text",
};

export interface SpecAction {
    type: string,
    value?: any,
    target?: any,
}

export function specReducer(oldSpec: ProjectSpec, action: SpecAction) {
    const newSpec: ProjectSpec = {
        ...(oldSpec ?? { title: "" }),
        scenes: oldSpec?.scenes || [],
    };

    newSpec.changed = true;

    const sceneIndex = getIndexByID(newSpec.scenes, action.target);

    switch (action.type) {
        case SpecActionType.Load:
            Object.assign(newSpec, action.value);
            newSpec.changed = false;
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.AddScene:
            newSpec.scenes.push({
                id: uuid.v4(),
                baseSourceID: action.value,
                objects: [],
            });
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.SetProjectTitle:
            newSpec.title = action.value as string;
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.AddCaption:
            newSpec.scenes[sceneIndex].objects.push({
                type: "text",
                id: uuid.v4(),
                startTime: action.value,
                duration: 1,
                value: {
                    text: "Enter text here",
                    // TODO coordinates
                },
            });
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.SetObjectCoordinates:
            for (const scene of newSpec.scenes) {
                for (const object of scene.objects) {
                    if (object.id === action.target) {
                        object.value = {
                            ...(object.value||{}),
                            ...action.value,
                        };
                        newSpec.modifiedAt = Date.now();
                    }
                }
            }
            break;
        case SpecActionType.SetObjectText:
            for (const scene of newSpec.scenes) {
                for (const object of scene.objects) {
                    if (object.id === action.target) {
                        object.value = {
                            ...(object.value||{}),
                            text: action.value,
                        };
                        newSpec.modifiedAt = Date.now();
                    }
                }
            }
            break;
        case SpecActionType.SetSceneTitle:
            newSpec.scenes[sceneIndex].title = action.value as string;
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.SetSceneOrder:
            const newScenes = [
                ...newSpec.scenes.slice(0, action.value.from),
                ...newSpec.scenes.slice(action.value.from + 1),
            ];
            newScenes.splice(action.value.to, 0, newSpec.scenes[action.value.from]);
            newSpec.scenes = newScenes;
            newSpec.modifiedAt = Date.now();
            break;
        case SpecActionType.DeleteScene:
            newSpec.scenes = newSpec.scenes.filter((scene, i) => i !== action.value);
            newSpec.modifiedAt = Date.now();
            break;
    }

    return newSpec;
}

export const defaultSpec: ProjectSpec = {
    changed: false,
    title: "",
    scenes: [],
};