import { useCallback } from "react";
import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";
import { TimelineActionType } from "./reducer";

export default function TimelineObjectContextMenu(props: any) {
    const remove = useCallback((e: any) => {
        props.dispatchAction({
            type: TimelineActionType.RemoveObject,
            sceneID: e.props.sceneID,
            objectID: e.props.objectID,
        });
    }, []);

    return (
        <Menu id="timeline-object">
            <Item onClick={remove}>Remove</Item>
        </Menu>
    );
}