import { ActionIcon, Button } from '@mantine/core';
import { ZoomInIcon, ZoomOutIcon } from '@modulz/radix-icons';
import styles from './timeline.module.scss';
import { getTimeStringFromSeconds } from './utils';

export interface TimelineToolbarProps {
    time: number,
    duration: number,
    zoomIn: () => void,
    zoomOut: () => void,
}

export default function TimelineToolbar(props: TimelineToolbarProps) {
    const time = getTimeStringFromSeconds(props.time);
    const duration = getTimeStringFromSeconds(props.duration);

    return (
        <div className={styles.toolbar}>
            <div />
            <div className={styles.currentTime}>
                <span>{time.time}<em>{time.fraction}</em></span>
                <span>{duration.time}<em>{duration.fraction}</em></span>
            </div>
            <div className={styles.toolbarButtons}>

                <ActionIcon variant="outline" size="sm" onClick={props.zoomIn}>
                    <ZoomInIcon />
                </ActionIcon>
                <ActionIcon variant="outline" size="sm" onClick={props.zoomOut}>
                    <ZoomOutIcon />
                </ActionIcon>
            </div>
        </div>
    );
}