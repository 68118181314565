import { AppShell } from '@mantine/core';
import { useMemo } from 'react';
// import styles from './editor-shell.module.scss';
import EditorPageAppBar from './appbar';
import Notifications from './notifications';

export interface EditorShellProps {
    className?: string,
    children?: any,
}

export default function EditorShell(props: EditorShellProps) {
    const header = useMemo(() => <EditorPageAppBar />, []);

    return <>
        <AppShell
            padding="md"
            className={props.className}
            header={header}
            styles={(theme) => ({
                main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
            })}>
            {props.children}
        </AppShell>
        <Notifications />
    </>;
}

// export interface EditorShellSidebarProps {
//     children?: any,
// }

// export function EditorShellSidebar(props: EditorShellSidebarProps) {
//     return <div className={styles.sidebar}>
//         {props.children}
//     </div>;
// }

// export interface EditorShellContentProps {
//     children?: any,
// }

// export function EditorShellContent(props: EditorShellContentProps) {
//     return <div className={styles.content}>
//         {props.children}
//     </div>;
// }