import { useCallback, useMemo } from "react";
import { Rnd } from "react-rnd";
import { OBJECT_HEIGHT } from "./constants";
import { parseDOMWidth } from "./utils";

export interface Props {
    width: number,
    x: number,
    y: number,
    children?: any,
    onChanging: () => void,
    onChange: (x: number, width: number) => void,
}

const enableResizingConfig = {
    top: false,
    right: true,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
};

export default function ResizeableObjectContainer(props: Props) {
    const size = useMemo(() => ({ width: props.width, height: OBJECT_HEIGHT }), 
        [props.width]);

    const position = useMemo(() => ({ x: props.x, y: props.y }), [props.x, props.y]);

    const onMoving = useCallback(() => props.onChanging(), [props.onChanging]);

    const onResizeStop = useCallback((e, d, ref, delta, position) => {
        return props.onChange(position.x, parseDOMWidth(ref.style.width));
    }, [props.onChange]);

    return (
        <Rnd size={size}
             position={position}
             onResizeStart={onMoving}
             onDragStart={onMoving}
             onDragStop={(e, d) => props.onChange(d.x, props.width)}
             bounds={'parent'}
             dragAxis={'x'}
             enableResizing={enableResizingConfig}
             onResizeStop={onResizeStop}>
            {props.children}
        </Rnd>
    );
}