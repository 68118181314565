import { useMutation, useQuery } from 'react-query';
import { loadExports, loadProject, loadSources } from '../../loaders';
import { Project } from '../../types/project';
import React, { useEffect, useReducer } from 'react';
import { ProjectSpec } from '../../types/spec';
import * as API from '../../core/api';
import { useDebounce } from 'react-use';
import { defaultSpec, SpecActionType } from '../../core/reducer';
import { defaultUIState, EditorPageState } from './reducer/ui';
import EditorPageInner from './page';
import { useParams } from "react-router-dom";
import { editorPageReducer } from './reducer';

export interface EditorPageProps {
    projectID: string,
}

export interface IEditorPageContext {
    projectID: string,
    project: Project,
    state: {
        ui: EditorPageState,
        spec: ProjectSpec,
    }
    // spec: ProjectSpec,
    // dispatchSpecAction: any,
    // uiState: EditorPageState,
    dispatch: (action: any) => void,
    // video: Video,
    exports: any[],
    sources: Record<string, any>, // TODO use proper type
}

export const EditorPageContext = React.createContext<IEditorPageContext>(null as any);

export default function EditorPage(props: EditorPageProps) {
    const params = useParams();
    const projectID = props.projectID || params.projectID;

    const key = ["project", projectID];

    const { data, isLoading } = useQuery<Project>(key, loadProject(projectID),
        { enabled: true });

    const { data: exports } = useQuery(["exports", projectID], loadExports(projectID), 
        { enabled: true, refetchInterval: 5000 });

    const [ state, dispatch ] = useReducer(editorPageReducer, {
        ui: defaultUIState,
        spec: defaultSpec,
    });

    useEffect(() => {
        if (data?.spec?.scenes && !state.spec.changed) {
            console.log("Loading API data into reducer", data.spec);
            dispatch({ type: SpecActionType.Load, value: data.spec });
        }
    }, [typeof data]);

    const specMutation = useMutation((spec: ProjectSpec) => API.save(projectID, spec), 
        { retry: 3 });

    useDebounce(
        () => {
            if (state.spec.changed === true) { // do not simplify, might be undefined
                specMutation.mutate(state.spec);
            }
        },
        2000,
        [state.spec]
    );

    const sourceIDs = state.spec.scenes.map(s => s.baseSourceID);
    const {data: sources} = useQuery(["sources"], loadSources(sourceIDs),
        { enabled: sourceIDs.length > 0 });

    if (!data) {
        return "";
    }

    const context: IEditorPageContext = {
        projectID: projectID,
        project: data,
        exports,
        state,
        dispatch,
        // uiState,
        // dispatchUIAction,
        // video: undefined,
        sources: sources || {},
    };

    return <EditorPageContext.Provider value={context}>
        <EditorPageInner project={data} context={context} />
    </EditorPageContext.Provider>;
}