import { useContextMenu } from 'react-contexify';
import styles from './timeline.module.scss';

export default function TimelineObject(props: any) {
    const { show } = useContextMenu({ 
        id: "timeline-object", 
        props: {
            sceneID: props.sceneID,
            objectID: props.id,
        }
    });

    const classes = [styles.object];

    if (props.className) {
        classes.push(props.className);
    }

    if (props.faded) {
        classes.push(styles.faded);
    }

    if (props.selected) {
        classes.push(styles.selected);
    }

    return (
        <button className={classes.join(" ")} style={props.style} onContextMenu={show} onClick={props.onClick}>
            Object {props.id}
        </button>
    );
}