import { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useInterval } from 'react-use';
import styles from './timeline.module.scss';
import { TimelineCurrentTime, TimelineScene } from './types';
import { getSceneTimeFromXCoordinate, getXCoordinateFromSceneTime } from './utils';

export interface Props {
    scenes: TimelineScene[],
    currentTime: TimelineCurrentTime,
    pxWidthPerSecond: number,
    setCurrentTime?: (time: TimelineCurrentTime) => void,
}

export default function TimelineTimeHandle(props: Props) {
    const [x, setX] = useState(0);
    const [dragging, setDragging] = useState<number|null>(null);

    useInterval(() => {
        const elem = document.querySelector("." + styles.scenes) as HTMLUListElement;

        if (dragging === null) {
            const _x = getXCoordinateFromSceneTime(props.scenes, props.currentTime, 
                props.pxWidthPerSecond);
            setX(_x - elem.scrollLeft);
        } else {
            setX(dragging);
            if (dragging < 50) {
                elem.scrollBy(-2, 0);
            } else if (dragging > window.innerWidth - 50) {
                elem.scrollBy(2, 0);
            }
        }
    }, 10);

    const onMouseDown = useCallback((e: MouseEvent) => {
        // document.title = "drag start";
        setDragging(e.clientX);
    }, [])

    const onMouseEvent = useCallback((e: MouseEvent) => {
        if (dragging !== null) {
            if (e.buttons % 2 === 0) {
                const elem = document.querySelector("." + styles.scenes) as HTMLUListElement;
                const absoluteX = dragging + elem.scrollLeft;
                const time = getSceneTimeFromXCoordinate(props.scenes, absoluteX, props.pxWidthPerSecond);
                props.setCurrentTime?.(time);
                setDragging(null);
            } else {
                setDragging(e.clientX);
            }
        }
    }, [dragging]);

    useEffect(() => {
        window.addEventListener("mousemove", onMouseEvent, false);
        window.addEventListener("mouseup", onMouseEvent, false);
        window.addEventListener("mousedown", onMouseEvent, false);
        return () => {
            window.removeEventListener("mousemove", onMouseEvent);
            window.removeEventListener("mouseup", onMouseEvent);
            window.removeEventListener("mousedown", onMouseEvent);
        };
    }, [dragging]);

    return (
        <div className={styles.timeHandle} data-dragging={dragging !== null ? "true" : "false"} style={{ left: x }} onMouseDown={onMouseDown}>
            <svg width="12" 
                 height="14" 
                 viewBox="0 0 12 14" 
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.16667V7.67376C0 7.98888 0.127471 8.2906 0.353409 8.51026L5.18674 13.2093C5.63955 13.6496 6.36045 13.6496 6.81326 13.2093L11.6466 8.51026C11.8725 8.2906 12 7.98888 12 7.67376V1.16667C12 0.522335 11.4777 0 10.8333 0H1.16667C0.522335 0 0 0.522334 0 1.16667Z" />
            </svg>
        </div>
    );
}