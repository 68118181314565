import * as api from '../core/api';

export function loadSession() {
    return async () => await api.get("session");
}

export function loadExports(projectID: string) {
    return async () => await api.get("exports/list", { projectID });
}

export function loadProjects() {
    return async () => await api.get("projects/list");
}

export function loadProject(id: string) {
    return async () => await api.get("projects/get", { id });
}

export function loadSources(ids: string[]) {
    return async () => await api.get("sources/get", { id: ids });
}