import createTexture from "gl-texture2d";

export enum CanvasType {
    Simple,
    WebGL,
}

export declare type Input = HTMLVideoElement | HTMLCanvasElement;

export default class Canvas {
    public readonly element: HTMLCanvasElement;
    public readonly context: RenderingContext|null;

    public constructor(public readonly width: number, 
                       public readonly height: number, 
                       public readonly type = CanvasType.Simple) {
        this.element = document.createElement("canvas");
        this.element.width = width;
        this.element.height = height;
        this.element.style.display = 'none';
        document.body.appendChild(this.element);

        if (type === CanvasType.WebGL) {
            this.context = this.element.getContext("webgl") 
                || this.element.getContext("experimental-webgl");

            if (!this.context) {
                console.log("Unable to get GL context.");
                return;
            }

            const gl = this.context as WebGL2RenderingContext;

            // initialize GL context
            gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
            const buffer = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
            gl.bufferData(
                gl.ARRAY_BUFFER,
                new Float32Array([-1, -1, -1, 4, 4, -1]), // see a-big-triangle
                gl.STATIC_DRAW
            );
            gl.viewport(0, 0, this.element.width, this.element.height);
        } else {
            this.context = this.element.getContext("2d");
        }
    }

    public getContext() {
        return this.context as CanvasRenderingContext2D;
    }

    public getGLContext() {
        return this.context as WebGL2RenderingContext;
    }

    public draw(input: Input) {
        if (this.context) {
            this.getContext().drawImage(input, 0, 0, this.width, this.height);
        }
    }

    public drawTransition(transition: any, progress: number, from: Input, to: Input, ...args: any[]) {
        const gl = this.getGLContext();

        const fromTexture = createTexture(gl, from);
        fromTexture.minFilter = gl.LINEAR;
        fromTexture.magFilter = gl.LINEAR;
        
        const toTexture = createTexture(gl, to);
        toTexture.minFilter = gl.LINEAR;
        toTexture.magFilter = gl.LINEAR;

        transition.draw(progress, fromTexture, toTexture, ...args);
    }

    public destroy() {
        if (this.type === CanvasType.WebGL) {
            this.element.remove();
        } else {
            this.element.remove();
        }
    }
}