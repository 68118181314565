import { Video } from "./types";

export function createVideoElement(id: string, url: string) {
    const elem = document.createElement("video");
    elem.setAttribute("data-index", id);

    elem.style.display = 'none';

    elem.crossOrigin = "anonymous";

    const source = document.createElement("source") as HTMLSourceElement;
    source.src = url;
    source.type = "video/mp4";
    
    elem.appendChild(source);

    return elem;
}

export async function initializeVideoElement(elem: HTMLVideoElement) {
    elem.muted = true;
    elem.currentTime = 0;
    
    await elem.play();
    await elem.pause();
}

export function parseVideoTime(time: number, videos: Video[]): { index: number, currentTime: number } {
    let t = 0;
    for (let index = 0; index < videos.length; index++) {
        const video = videos[index];
        if (t + video.duration > time) {
            return {
                index,
                currentTime: time - t,
            };
        }
        t += video.duration;
    }
    const video = videos[videos.length - 1];
    return {
        index: videos.length - 1,
        currentTime: video.duration,
    };
}

export function sum(values: number[]) {
    let sum = 0;
    for (const v of values) {
        sum += v;
    }
    return sum;
}