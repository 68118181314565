import { createContext, useCallback, useMemo } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { SCENE_GAP } from "./constants";

import styles from './timeline.module.scss';

export interface SceneListItemProps {
    id: string,
    index: number,
    children?: any,
}

export const TimelineSceneDragHandle = createContext<any>({});

export default function TimelineSceneListItem(props: SceneListItemProps) {
    const onMouseMove = useCallback(e => {
        const list = document.querySelector("." + styles.scenes) as HTMLUListElement;
        const globalMouseX = Math.max(0, e.clientX - list.offsetLeft + list.scrollLeft);

        let sceneID: string;

        let node = e.target as HTMLElement;
        while (node) {
            if (node.tagName.toLocaleLowerCase() === "li") {
                sceneID = node.getAttribute("data-rbd-draggable-id");
                break;
            }
            node = node.parentElement;
        }

        // console.log("mouse move", globalMouseX, sceneID);
    }, []);

    const containerStyles = useMemo(() => ({ 
        marginLeft: props.index >= 0 ? SCENE_GAP : 0,
    }), [props.index]);

    return (
        <Draggable key={props.id} draggableId={props.id} index={props.index}>
            {(dragProvided: DraggableProvided) => (
                <TimelineSceneDragHandle.Provider value={dragProvided.dragHandleProps}>
                    <li key={props.index + "--" + props.id}
                        ref={(ref) => dragProvided.innerRef(ref)}
                        {...dragProvided.draggableProps}
                        style={{
                            ...(dragProvided.draggableProps.style ?? {}),
                            ...containerStyles,
                        }}
                        onMouseMove={onMouseMove}>
                        {props.children}
                    </li>
                </TimelineSceneDragHandle.Provider>
            )}
        </Draggable>
    );
}