import { Textarea } from "@mantine/core";
import { useContext } from "react";
import { EditorPageContext } from ".";
import Match from "../match";

import styles from './object-properties.module.scss';

export function TextObjectProperties(props: any) {
    return <>
        <Textarea placeholder="Enter text here..."
            label="Text"
            value={props.object.value.text}
            />
    </>
}

export default function ObjectProperties(props: any) {
    const { dispatch, state } = useContext(EditorPageContext);

    let selectedObject = null;
    if (state.ui.selectedObject?.objectID) {
        for (const scene of state.spec.scenes) {
            for (const object of scene.objects) {
                if (object.id === state.ui.selectedObject.objectID) {
                    selectedObject = object;
                }
            }
        }
    }

    if (!selectedObject) {
        return null;
    }

    return <div className={styles.container}>
        <Match id={selectedObject.type}>{{
            text: () => (
                <TextObjectProperties object={selectedObject} />
            ),
        }}</Match>
    </div>;
}