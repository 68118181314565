import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import './App.scss';
import ProjectListPage from './components/project-list';
import { BrowserRouter } from "react-router-dom";
import EditorPage from './components/editor';
import AccountPage from './components/account';
import TimelineObject from './components/timeline/object';
import Timeline from './components/timeline/timeline';
import TimelineScene from './components/timeline/scene';

import "react-contexify/dist/ReactContexify.css";

export const queryClient = new QueryClient();

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <>
      <Helmet>
        <title>streamloops...</title>
        <link rel="stylesheet" href="https://unpkg.com/carbon-components/css/carbon-components.min.css" />
        <link rel="stylesheet" media="all" href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,500,700,800" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider theme={{ colorScheme }}>
            <BrowserRouter>
              <Routes>
                <Route index element={<ProjectListPage />} />
                <Route path="project/:projectID" element={<EditorPage />} />
                <Route path="account" element={<AccountPage />} />
              </Routes>
            </BrowserRouter>
          </MantineProvider>
        </ColorSchemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
