// import {signIn, signOut, useSession} from 'next-auth/client';
import Head from "react-helmet";
import {Component, PureComponent, useCallback, useEffect} from "react";
import {
    Content,
    Header,
    HeaderGlobalBar,
    HeaderMenu,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    Loading,
    SkipToContent,
} from 'carbon-components-react';

import {MenuItem} from "./menu-item";
import styles from './shell.module.scss';
import {signOut, useSession} from "../../core/session";

// import { useSession, signIn, signOut } from "next-auth/react";

import {ChevronLeft32, Video32} from "@carbon/icons-react";

export interface ShellProps {
    loading?: boolean,
    title?: string,
    menu: MenuItem[],
    children?: any,
    isHome?: boolean,
    left?: any,
    contentRef?: any,
}

export interface ShellHeaderProps {
    title: string,
    username: string,
    menu?: MenuItem[],
    left?: any,
    isHome?: boolean,
}

export class ShellHeader extends PureComponent<ShellHeaderProps> {
    render() {
        return <Header aria-label={this.props.title}>
            <SkipToContent/>

            <HeaderName href="/" prefix="">
                {this.props.isHome ? <Video32 /> : <ChevronLeft32 />}
                <span className={styles.logo} title={"Return to your projects"}>
                    <span>stream</span><strong>loops</strong>
                </span>
            </HeaderName>
            {!!this.props.title && <div className={styles.title}>{this.props.title}</div>}
            {!!this.props.menu?.length && <HeaderNavigation aria-label="">
                {this.props.menu?.map(item => (
                    <HeaderMenu key={item.label} aria-label={item.label} menuLinkName={item.label}>
                        {item.children?.map(item => (
                            <HeaderMenuItem key={item.label} onClick={() => item.onClick?.()} className={styles.menuItem} aria-disabled={item.disabled}>
                                <div className={styles.menuItemWrapper}>
                                    {item.icon && <span className={styles.icon}>{item.icon}</span>}
                                    <span className={styles.label}>{item.label}</span>
                                    {item.shortcut && <span className={styles.shortcut}>{item.shortcut}</span>}
                                </div>
                            </HeaderMenuItem>
                        ))}
                    </HeaderMenu>
                ))}
            </HeaderNavigation>}
            {this.props.left}
            <HeaderGlobalBar>
                <ul className={styles.actionBar}>
                <HeaderMenu aria-label={"Signed in as " + this.props.username}
                            menuLinkName={"Signed in as " + this.props.username}>
                    <HeaderMenuItem key={"account"} href="/account">Account Settings</HeaderMenuItem>
                    <HeaderMenuItem key={"sign-out"} href="#" onClick={() => signOut()}>Sign Out</HeaderMenuItem>
                </HeaderMenu>
                </ul>
            </HeaderGlobalBar>
        </Header>;
    }
}

const contentStyle = { padding: 0, display: 'flex', flexDirection: 'column' };

export default function Shell(props: ShellProps) {
    const [session, loading] = useSession();

    // const { data: session, status } = useSession();

    // const loading = status === "loading";

    console.log("session", session, loading);

    const propsSayLoading = typeof props.loading !== 'undefined' && props.loading;
    const ready = !!(session && !loading && !propsSayLoading);

    return <div className={styles.shell}>
        <Head>
            <title>{props.title}</title>
        </Head>
        {ready ? <>
            <ShellHeader title={props.title} 
                        menu={props.menu} 
                        left={props.left} 
                        username={session?.user?.name || "N/A"} 
                        isHome={props.isHome} />
            <Content key={"content"} style={contentStyle as any}>
                {props.children}
            </Content>
        </> : <Loading />}
    </div>
}