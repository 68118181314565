import { DocumentVideo32, Information32, Template32, TextCreation32, Video32 } from '@carbon/icons-react';
import { Button, ActionIcon, Text } from '@mantine/core';
import { useContext } from 'react';
import { EditorPageContext } from '.';
import { SpecActionType } from '../../core/reducer';
import { PropertiesTab } from './properties';
import { EditorPageAction } from './reducer/ui';
import styles from './toolbox.module.scss';

export interface ToolboxProps {
    items: ToolboxItemProps[],
}

export interface ToolboxItemProps {
    icon: any,
    label: string,
    selected?: boolean,
    onClick?: any,
}

export function ToolboxItem(props: ToolboxItemProps) {
    return <Button leftIcon={props.icon} 
                   variant="default" 
                   className={props.selected ? styles.selected : ""}
                   onClick={() => props.onClick?.()}>
        {props.label}
    </Button>;
}

export function Toolbox(props: ToolboxProps) {
    return <div className={styles.container}>
        {props.items?.map((item, i) => (
            <ToolboxItem {...item} key={i} />
        ))}
    </div>
}

export default function EditorPageToolbox(props: any) {
    const {dispatch, state} = useContext(EditorPageContext);

    return (
        <Toolbox items={[
            {
                label: "Add Text",
                icon: <TextCreation32 />,
                selected: state.ui.tab === PropertiesTab.Text,
                onClick: () => dispatch({
                    type: SpecActionType.AddCaption,
                    target: state.ui.currentTime.sceneID,
                    value: state.ui.currentTime.sceneCurrentTime,
                }),
            },
            // {
            //     label: "Add Elements",
            //     icon: <Template32 />,
            //     selected: state.ui.tab === PropertiesTab.Elements,
            //     onClick: () => dispatch({
            //         type: EditorPageAction.SetTab,
            //         value: PropertiesTab.Elements,
            //     }),
            // },
        ]} />
    );
}