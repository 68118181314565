import { SCENE_GAP } from "./constants";
import { TimelineCurrentTime, TimelineScene, TimelineSceneInput, TimelineSceneObject, TimelineSource } from "./types";

export function parseDOMWidth(w: string|number) {
    if (typeof w === 'string') {
        return parseInt(w.replace(/[^\d]+/, ''), 10);
    }
    return w
}

export function getTimeStringFromSeconds(input: number): { time: string, fraction: string } {
    const minutes = Math.floor(input / 60);
    const preciseSeconds = input - minutes * 60;
    const seconds = Math.floor(preciseSeconds);
    const fraction = Math.floor((preciseSeconds - seconds) * 100);

    const time = [
        minutes < 10 ? "0" : "",
        minutes,
        ":",
        seconds < 10 ? "0" : "",
        seconds,
    ].join("");

    return {
        time,
        fraction: "." + (fraction < 10 ? "0" : "") + fraction,
    };
}

export function getIndexByID(array: {id: string}[], id: string) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
            return i;
        }
    }
    return -1;
}

export function updateObjectRows(input: TimelineSceneObject[]) {
    const objects = input.map(o => ({...o}));

    let rows = 1;

    for (let i = 0; i < objects.length; i++) {
        const o1 = objects[i];
        const start1 = o1.startTime;
        const end1 = start1 + o1.duration;

        o1.row = 0;

        for (let j = 0; j < i; j++) {
            const o2 = objects[j];
            const start2 = o2.startTime;
            const end2 = start2 + o2.duration;
            
            const fullyInside = (start2 >= start1 && end2 <= end1) || (start1 >= start2 && end1 <= end2);
            const startOverlaps = start2 >= start1 && start2 <= end1;
            const endOverlaps = end2 >= start1 && end2 <= end1;

            if (fullyInside || startOverlaps || endOverlaps) {
                o1.row++;
                rows = Math.max(rows, o1.row + 1);
            }
        }
    }

    return { objects, rows };
}

export function getProjectTimeFromSceneTime(scenes: TimelineScene[], currentTime: TimelineCurrentTime) {
    let duration = 0;
    let time = 0;
    scenes.forEach(s => {
        if (s.id === currentTime.sceneID) {
            time = duration + currentTime.sceneCurrentTime;
        }
        duration += s.duration
    });
    return { duration, currentTime: time };
}

export function getXCoordinateFromSceneTime(scenes: TimelineScene[], currentTime: TimelineCurrentTime, pxWidthPerSecond: number) {
    let duration = 0;
    let previousScenes = 0;

    for (const scene of scenes) {
        if (scene.id === currentTime.sceneID) {
            const seconds = duration + currentTime.sceneCurrentTime;
            return (seconds * pxWidthPerSecond) + (previousScenes+1) * SCENE_GAP;
        }
        duration += scene.duration;
        previousScenes++;
    }    
}

export function getSceneTimeFromXCoordinate(scenes: TimelineScene[], x: number, pxWidthPerSecond: number) {
    let maxX = 0;
    for (const scene of scenes) {
        maxX += scene.duration * pxWidthPerSecond;
    }
    maxX += (scenes.length) * SCENE_GAP;

    x = Math.max(0, Math.min(maxX, x));

    let duration = 0;
    let previousScenes = 0;

    for (const scene of scenes) {
        const startX = (duration * pxWidthPerSecond) + (previousScenes+1) * SCENE_GAP;
        const endX = startX + scene.duration * pxWidthPerSecond;

        if (x >= startX && x < endX) {
            const sceneID = scene.id;
            const sceneCurrentTime = (x - startX) / pxWidthPerSecond;
            return { sceneID, sceneCurrentTime };
        }

        if (x < startX) {
            // dropped in gap between scenes - jump to start of next scene
            return { 
                sceneID: scene.id,
                sceneCurrentTime: 0,
            };
        }

        duration += scene.duration;
        previousScenes++;
    }

    const lastScene = scenes[ scenes.length - 1 ];
    return {
        sceneID: lastScene.id,
        sceneCurrentTime: lastScene.duration,
    };
}

export function mergeScenesWithSources(scenes: TimelineSceneInput[], sources: Record<string, any>): TimelineScene[] {
    return scenes.map(scene => {
        const source = sources[scene.baseSourceID];
        return ({
            ...(source || {}),
            ...scene,
        });
    });
}