import { useCallback } from "react";
import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";
import { TimelineActionType } from "./reducer";
import { TimelineScene } from "./types";

export default function TimelineSceneContextMenu(props: { dispatcAction: any, scenes: TimelineScene[] }) {
    const openClip = useCallback((e: any) => {
        const sceneID = e.props.sceneID;
        const scene = props.scenes.filter(s => s.id === sceneID)[0];
        const clipID = (scene as any).key;
        window.open("https://clips.twitch.tv/" + clipID, "_blank");
    }, [props.scenes]);

    const remove = useCallback((e: any) => {
        props.dispatchAction({
            type: TimelineActionType.RemoveScene,
            sceneID: e.props.sceneID,
        });
    }, []);

    return (
        <Menu id="timeline-scene">
            <Item onClick={openClip}>View Original Clip</Item>
            <Item onClick={remove}>Remove clip</Item>
        </Menu>
    );
}