export default class FPSClock {
    private frameTimes = [];
    private lastLogTime = 0;

    constructor() {
    }

    public onFrame() {
        this.frameTimes.push(Date.now());
        if (this.frameTimes.length > 10) {
            this.frameTimes.shift();
        }

        if ((Date.now() - this.lastLogTime) > 5000 && this.frameTimes.length > 5) {
            this.lastLogTime = Date.now();
            console.log("FPS: " + this.compute());
        }
    }

    public onPause() {
        this.frameTimes = [];
    }

    public compute() {
        const first = this.frameTimes[0];
        const last = this.frameTimes[this.frameTimes.length - 1];
        const averageFrameMS = (last - first) / this.frameTimes.length;
        return 1000 / averageFrameMS;
    }
}