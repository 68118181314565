"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Coordinates = void 0;
var OriginX;
(function (OriginX) {
    OriginX[OriginX["Left"] = 0] = "Left";
    OriginX[OriginX["Center"] = 1] = "Center";
    OriginX[OriginX["Right"] = 2] = "Right";
})(OriginX || (OriginX = {}));
var OriginY;
(function (OriginY) {
    OriginY[OriginY["Top"] = 0] = "Top";
    OriginY[OriginY["Center"] = 1] = "Center";
    OriginY[OriginY["Bottom"] = 2] = "Bottom";
})(OriginY || (OriginY = {}));
var Coordinates = /** @class */ (function () {
    function Coordinates(fabric, canvasDimensions, originX, originY) {
        if (originX === void 0) { originX = OriginX.Left; }
        if (originY === void 0) { originY = OriginY.Top; }
        this.fabric = fabric;
        this.canvasDimensions = canvasDimensions;
        this.originX = originX;
        this.originY = originY;
        this.x = 0;
        this.y = 0;
    }
    Coordinates.prototype.fromLeft = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, OriginX.Left, this.originY);
    };
    Coordinates.prototype.fromCenterX = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, OriginX.Center, this.originY);
    };
    Coordinates.prototype.fromRight = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, OriginX.Right, this.originY);
    };
    Coordinates.prototype.fromTop = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, this.originX, OriginY.Top);
    };
    Coordinates.prototype.fromCenterY = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, this.originX, OriginY.Center);
    };
    Coordinates.prototype.fromBottom = function () {
        return new Coordinates(this.fabric, this.canvasDimensions, this.originX, OriginY.Bottom);
    };
    Coordinates.prototype.position = function (x, y) {
        this.x = x;
        this.y = y;
        return this;
    };
    Coordinates.prototype.dimensions = function (dimensions) {
        this.objectDimensions = dimensions;
        return this;
    };
    Coordinates.prototype.text = function (text) {
        var textLayer = new this.fabric.Text(text, {});
        this.dimensions({
            width: textLayer.width,
            height: textLayer.height,
        });
        return this;
    };
    Coordinates.prototype.compute = function () {
        var originX = "left";
        var originY = "top";
        var x;
        var y;
        switch (this.originX) {
            case OriginX.Left:
                x = this.x;
                break;
            case OriginX.Center:
                originX = "center";
                x = (this.canvasDimensions.width / 2) + this.x;
                break;
            case OriginX.Right:
                originX = "right";
                x = this.canvasDimensions.width - this.x;
                break;
        }
        switch (this.originY) {
            case OriginY.Top:
                y = this.y;
                break;
            case OriginY.Center:
                originY = "center";
                y = (this.canvasDimensions.height / 2) + this.y;
                break;
            case OriginY.Bottom:
                originY = "bottom";
                y = this.canvasDimensions.height - this.y;
                break;
        }
        return {
            originX: originX,
            originY: originY,
            left: x,
            top: y,
        };
    };
    return Coordinates;
}());
exports.Coordinates = Coordinates;
