import { useQuery } from 'react-query';
import { loadProjects } from '../../loaders';
import Shell from '../layout/shell';
import styles from './project-list.module.scss';
import {Button, ExpandableSearch, Pagination, Search, Tile, TooltipIcon} from "carbon-components-react";
import { Project } from '../../types/project';
import { useCallback } from 'react';
import * as API from '../../core/api';
import { queryClient } from '../../App';

export interface ProjectListPageProps {
}

export default function ProjectListPage(props: ProjectListPageProps) {
    const {data, isLoading} = useQuery(["projects"], loadProjects(), 
        { enabled: true });

    const create = useCallback(async () => {
        const result = await API.createProject("Untitled");
        queryClient.invalidateQueries("projects");
    }, []);

    return <div className={styles.container}>
        <Shell isHome title="" menu={[]}>
            <div>
                <div className={styles.header}>
                    <h1>Your Projects</h1>
                    <Button size="sm" onClick={create}>Create Video</Button>
                </div>
                {data?.itemCount && <ul className={styles.list}>
                    {data.items.map((item: Project, index: number) => (
                        <li className={styles.project} key={item.id + "--" + index}>
                            <a href={`/project/${item.id}`} target="_blank">
                                <img src={item.thumbnail} alt="" />
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>}
                <div className={styles.footer}>
                    <Pagination onChange={() => {}}
                                page={1}
                                size={"lg"}
                                totalItems={data?.itemCount || 0}
                                pageSizes={[10, 25, 50, 100]} />
                </div>
            </div>
        </Shell>
    </div>;
}