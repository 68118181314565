import { Money16, Password16, User16 } from "@carbon/icons-react";
import { Input, Text, Button, Container, Paper, Progress, Radio, RadioGroup, Switch, Tabs, Title, InputWrapper } from "@mantine/core";
import AccountPageShell from "./account-page-shell";

import styles from './account-page.module.scss';
import planStyle from './plans.module.scss';
import { PasswordStrength } from "./password";
import { EnvelopeClosedIcon } from "@modulz/radix-icons";
import { useSession } from "../../core/session";

export default function AccountPage(props: any) {
    const [session, loading] = useSession();

    if (loading) {
        return <div>Loading</div>;
    }

    console.log("session", session);

    const planID = session?.plan?.id as string;
    const planName = planID[0].toLocaleUpperCase() + planID.substring(1);

    return <div className={styles.container}>
        <AccountPageShell>
            <Container size="md" padding={0}>
                <Paper padding={0} shadow="xs" radius="sm" withBorder>
                    <Tabs orientation="vertical" tabPadding={0}>
                        <Tabs.Tab label="Plans & Billing" icon={<Money16 />}>
                            <div className={planStyle.container}>
                                <Title order={2}>{planName} Plan</Title>
                                <Text>You have exported <strong>{session.usage.totalExportMinutesUsed} minutes</strong> of video this month. You have <strong>{session.usage.totalExportMinutesRemaining} minutes</strong> remaining.</Text>
                                <Text>Your current plan ({planName}) includes {session.plan.limits.exportMinutes} minutes of exported video per month and will reset on 2/20/2021.</Text>
                                <Progress className={planStyle.progressBar} 
                                          value={session.usage.totalExportMinutesUsed / session.plan.limits.exportMinutes * 100} 
                                          label={session.usage.totalExportMinutesUsed + " minutes used"}
                                          size="xl" 
                                          radius="xl" />

                                {/* <Title order={2}>Upgrade Plan</Title> */}

                                <RadioGroup
                                    variant="vertical"
                                    label="Change Plan"
                                    description={<Text>Upgrade your plan to unlock features. See the pricing page for more information.</Text>}
                                    spacing="xl"
                                    size="md">
                                    <Radio value="basic" disabled={planID === "basic"}>
                                        Basic {planID === "basic" && "(Current)"} <em>$9 / month</em>
                                    </Radio>
                                    <Radio value="professional" disabled={planID === "professional"}>
                                        Professional {planID === "professional" && "(Current)"} <em>$29 / month</em>
                                    </Radio>
                                    <Radio value="premium" disabled={planID === "premium"}>
                                        Premium {planID === "premium" && "(Current)"} <em>$79 / month</em>
                                    </Radio>
                                </RadioGroup>
                                <div className={planStyle.buttonBar}>
                                    {/* <Switch label="Annual Billing" /> */}
                                    <Button>Select Plan</Button>
                                </div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab label="Profile" icon={<User16 />}>
                            <Title order={2}>Account Profile</Title>
                            <InputWrapper
                                required
                                label="Your Name">
                                <Input
                                    icon={<User16 />}
                                    defaultValue={session.user.name}
                                    placeholder="Your name" />
                            </InputWrapper>
                            <InputWrapper
                                style={{ marginTop: '1rem' }}
                                required
                                label="Your Email Address">
                                <Input
                                    icon={<EnvelopeClosedIcon />}
                                    defaultValue={session.user.email}
                                    placeholder="Your email" />
                            </InputWrapper>
                            <Button style={{ marginTop: '1rem' }}>Save</Button>
                        </Tabs.Tab>
                        <Tabs.Tab label="Security" icon={<Password16 />}>
                            <Title order={2}>Account Password</Title>
                            <PasswordStrength />
                            <Button style={{ marginTop: '1rem' }}>Update Password</Button>
                        </Tabs.Tab>
                    </Tabs>
                </Paper>
            </Container>
        </AccountPageShell>
    </div>;
}