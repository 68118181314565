import styles from './properties.module.scss';
import {Text} from '@mantine/core';

export enum PropertiesTab {
    ClipDetails,
    Text,
    Elements,
}

export interface PropertiesProps {
    children?: any,
}

export default function Properties(props: PropertiesProps) {
    return <div className={styles.container}>
        {props.children}
    </div>;
}