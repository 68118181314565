import { ArrowLeft16, Light16, Moon16, Sun16, Sunny16, Sunrise16 } from '@carbon/icons-react';
import { Button, Avatar, Menu, Divider, Text, useMantineColorScheme, TextInput } from '@mantine/core';
import { AppShell, Affix, Burger, Header, MediaQuery, Navbar, useMantineTheme, ActionIcon, Notification } from '@mantine/core';
import { DownloadIcon, ExitIcon, GearIcon, ListBulletIcon } from '@modulz/radix-icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import styles from "./header.module.scss";

export interface HeaderProps {
    title?: any,
    backURL?: string,
    actionButtons?: ActionButton[],
    menu?: Record<string, MenuItem[]>,
    isHome?: boolean,
}

export interface ActionButton {
    label: string,
    onClick?: any,
    icon?: any,
    loading?: boolean,
}

export interface MenuItem {
    label: string,
    onClick?: any,
    icon?: any,
}

export default function AppHeader(props: HeaderProps) {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const [editingTitle, toggleEditingTitle] = useToggle(false);
    const navigate = useNavigate();

    const back = useCallback(() => {
        navigate(props.backURL ? props.backURL : "/");
    }, [props.backURL])

    return (
        <Header height={60} padding="xs" className={styles.container}>
            <div className={styles.wrapper} style={{ 
                color: colorScheme === 'dark' ? '#ffffff' : '#000000',
            }}>
                {!props.isHome && <ActionIcon size="lg" variant="hover" onClick={back}>
                    <ArrowLeft16 />
                </ActionIcon>}
                {!!props.title && <div className={styles.title}>
                    {props.title}
                </div>}
                <div style={{ display: 'flex', alignItems: 'center', flexGrow: '1' }} />
                <div className={styles.actionButtons}>
                    <Button onClick={() => toggleColorScheme()} key={"theme"} variant="outline" size="sm">
                        {colorScheme === "light" ? <Moon16 /> : <Light16 />}
                    </Button>
                    {props.actionButtons?.length > 0 && props.actionButtons.map(btn => (
                        <Button size="sm" 
                                loading={btn.loading}
                                onClick={() => btn.onClick()} 
                                key={btn.label}>
                            {btn.label}
                        </Button>
                    ))}
                </div>
                <Menu withArrow>
                    {!!props.menu && Object.keys(props.menu).map((label: string) => <>
                        <Menu.Label>{label}</Menu.Label>    
                        {props.menu[label].map((item: MenuItem) => (
                            <Menu.Item icon={item.icon} onClick={() => item.onClick?.()}>
                                {item.label}
                            </Menu.Item>        
                        ))}
                    </>)}
                    <Menu.Label>Project</Menu.Label>

                    <Menu.Item icon={<DownloadIcon />}>Exports...</Menu.Item>

                    <Divider />

                    <Menu.Label>Account</Menu.Label>
                    <Menu.Item icon={<GearIcon />}>Account Settings</Menu.Item>
                    <Menu.Item icon={<ExitIcon />}>Sign Out</Menu.Item>
                </Menu>
            </div>
        </Header>
    );
}