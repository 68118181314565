import { useMemo } from 'react';
import styles from './timeline.module.scss';

export default function TimelineTimestamps(props: { start: number, duration: number, pxWidthPerSecond: number }) {
    const items = useMemo(() => {
        let interval = 1;
        let width = props.pxWidthPerSecond;

        if (props.pxWidthPerSecond < 50) {
            interval = 10;
            width = props.pxWidthPerSecond * 10;
        } else if (props.pxWidthPerSecond < 100) {
            interval = 4;
            width = props.pxWidthPerSecond * 4;
        }

        let items: any[] = [];
        for (let i = 0; i < props.duration; i += interval) {
            items.push(<span className={styles.timestamp} style={{ width }}>
                {i}
            </span>);
        }
        return items;
    }, [props.start, props.duration, props.pxWidthPerSecond]);

    return <div className={styles.timestamps} style={{ width: props.pxWidthPerSecond * props.duration }}>
        {items}
    </div>;
}