import { Checkmark32, Edit16 } from "@carbon/icons-react";
import { ActionIcon, TextInput, Text } from "@mantine/core";
import { useCallback, useContext } from "react";
import { useToggle } from "react-use";
import { EditorPageContext } from ".";
import { queryClient } from "../../App";
import { SpecActionType } from "../../core/reducer";
import * as API from "../../core/api";
import AppHeader from "../layout2/header";

export default function EditorPageAppBar(props: any) {
const [editingTitle, toggleEditingTitle] = useToggle(false);
    const context = useContext(EditorPageContext);

    const hasActiveExport = !!context.exports?.filter(e => e.status === "ACTIVE").length;

    const addClip = useCallback(async () => {
        const id = prompt("Enter clip ID");
        const items = await API.get("sources/register", { ids: id });
        const sourceID = items[0].id;
        context.dispatch({
            type: SpecActionType.AddScene,
            value: sourceID,
        });
    }, []);

    const exportVideo = useCallback(async () => {
        await API.createExport(context.projectID, true, context.state.spec);
        queryClient.invalidateQueries("exports");
    }, []);

    return (
        <AppHeader title={!editingTitle ? <>
            <h1><Text>{context.state.spec.title || "Untitled Project"}</Text></h1>
            <ActionIcon size="lg" variant="hover" onClick={() => toggleEditingTitle()}>
                <Edit16 />
            </ActionIcon>
        </> : <>
            <TextInput value={context.state.spec.title}
                onChange={e => context.dispatch({
                    type: SpecActionType.SetProjectTitle,
                    value: e.target.value,
                })} />
            <ActionIcon size="lg" variant="hover" onClick={() => toggleEditingTitle()}>
                <Checkmark32 />
            </ActionIcon>
        </>}
            actionButtons={[
                { label: "Add Clip", onClick: addClip },
                { label: "Export Video", onClick: exportVideo, loading: hasActiveExport },
            ]} />
    );
}