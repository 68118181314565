import { Affix, Button, Notification } from "@mantine/core";
import { useContext } from "react";
import { EditorPageContext } from ".";

export default function Notifications(props: any) {
    const {exports} = useContext(EditorPageContext);

    return (
        <Affix position={{ bottom: 20, right: 20 }}>
            {exports?.map(e => {
                const timestamp = new Date(e.created).getTime();
                const since = Date.now() - timestamp;

                if (since > (1000 * 60 * 20)) {
                    return null;
                }

                switch (e.status) {
                    case "ACTIVE":
                        return (
                            <Notification
                                loading
                                title="Exporting Video..."
                                onClose={() => { }}
                                disallowClose>
                                Your video will be available to download shortly.
                            </Notification>
                        )
                    case "SUCCESS":
                        return (
                            <Notification
                                color="teal"
                                title="Export Complete"
                                onClose={() => { }}>
                                <Button onClick={() => {
                                    window.location.href = "/api/exports/download?id=" + e.id;
                                }}>Download Video</Button>
                            </Notification>
                        )
                    default:
                        return (
                            <Notification
                                color="red"
                                title="Export Failed"
                                onClose={() => { }}>
                                Your video could not be exported.
                            </Notification>
                        )
                }
            })}
        </Affix>
    );
}