import cloneDeep from 'clone-deep';

import { MAX_PX_WIDTH_PER_SECOND, MIN_PX_WIDTH_PER_SECOND } from "../constants";
import { TimelineInternalState } from "../types";

export enum TimelineInternalActionType {
    ZoomIn = "timeline:internal:zoom-in",
    ZoomOut = "timeline:internal:zoom-out",
    ChangingObject = "timeline:internal:changing-object",
    Hover = "timeline:internal:hover",
    StartEditingTitle = "timeline:internal:start-editing-title",
}

export interface TimelineInternalAction {
    type: TimelineInternalActionType,
    sceneID?: any,
    objectID?: any,
    value?: any,
}

export function internalStateReducer(previous: TimelineInternalState, action: TimelineInternalAction): TimelineInternalState {
    const next = cloneDeep(previous);
    
    switch (action.type) {
        case TimelineInternalActionType.StartEditingTitle:
            next.editingTitleForSceneID = action.sceneID;
            break;
        case TimelineInternalActionType.ZoomIn:
            next.pxWidthPerSecond *= 1.25;
            break;
        case TimelineInternalActionType.ZoomOut:
            next.pxWidthPerSecond *= 0.75;
            break;
        case TimelineInternalActionType.ChangingObject:
            if (action.objectID) {
                next.movingObjectID = action.objectID;
            } else {
                next.movingObjectID = undefined;
            }
            break;
        case TimelineInternalActionType.Hover:
            next.hoverPosition = {
                sceneID: action.sceneID,
                x: action.value,
            };
    }

    next.pxWidthPerSecond = Math.max(MIN_PX_WIDTH_PER_SECOND, next.pxWidthPerSecond);
    next.pxWidthPerSecond = Math.min(MAX_PX_WIDTH_PER_SECOND, next.pxWidthPerSecond);

    return next;
}