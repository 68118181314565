"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.connect = void 0;
var coordinates_1 = require("./coordinates");
var FabricLayer = /** @class */ (function () {
    function FabricLayer(id, clipDuration, start, duration) {
        this.id = id;
        this.clipDuration = clipDuration;
        this.start = start;
        this.duration = duration;
        this.dimensions = { width: 1920, height: 1080 };
        this.progressAsPercent = 0;
        this.time = 0;
    }
    FabricLayer.prototype.active = function () {
        // console.log("active?");
        // console.table({
        //     percent: this.progressAsPercent,
        //     duration: this.clipDuration,
        //     time: this.time,
        //     start: this.start,
        //     end: (this.start + this.duration),
        // });
        return this.time >= this.start && this.time < (this.start + this.duration);
    };
    FabricLayer.prototype.onRender = function (progress) {
        // document.title = "no override";
    };
    FabricLayer.prototype.onClose = function () {
    };
    FabricLayer.prototype.coords = function () {
        return new coordinates_1.Coordinates(this.fabric, this.dimensions);
    };
    FabricLayer.prototype.padding = function (multiplier) {
        if (multiplier === void 0) { multiplier = 1; }
        var value = this.x(5);
        if (multiplier === 0) {
            value /= 1.618;
        }
        else {
            for (var i = 1; i < multiplier; i++) {
                value *= 1.618;
            }
        }
        return value;
    };
    FabricLayer.prototype.x = function (percent) {
        var fraction = percent / 100;
        return this.dimensions.width * fraction;
    };
    FabricLayer.prototype.y = function (percent) {
        var fraction = percent / 100;
        return this.dimensions.height * fraction;
    };
    return FabricLayer;
}());
exports.default = FabricLayer;
function connect(layer) {
    return function (props) {
        layer.dimensions = { width: props.width, height: props.height };
        // @ts-ignore
        layer.fabric = props.fabric;
        return {
            setDuration: function (duration) { return layer.clipDuration = duration; },
            onRender: function (progress, canvas) {
                layer.canvas = canvas;
                layer.progressAsPercent = progress;
                layer.time = layer.clipDuration * progress;
                layer.onRender(progress);
            },
            onClose: function () { return layer.onClose(); },
        };
    };
}
exports.connect = connect;
