import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// if (typeof window !== "undefined") {
  // console.log("Development Mode - Installing Mocking Service Worker ...");
  // const { worker } = require('./mock/browser');
  // worker.start();
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);