import { useCallback, useContext, useMemo, useReducer, useRef, useState } from 'react';
import styles from './scene.module.scss';
import { Rnd } from 'react-rnd';
import TimelineObject from './object';
import { parseDOMWidth, updateObjectRows } from './utils';
import ResizeableObjectContainer from './resizable-object-container';
import { LAYER_PADDING, OBJECT_HEIGHT, SCENE_GAP } from './constants';
import { TimelineSceneDragHandle } from './scene-list-item';
import { TimelineCurrentTime, TimelineScene, TimelineSceneObject } from './types';
import { TimelineAction, TimelineActionType } from './reducer';
import TimelineTimestamps from './timestamps';
import { useContextMenu } from 'react-contexify';
import { ActionIcon, LoadingOverlay, TextInput, Tooltip } from '@mantine/core';
import { Checkmark16, Checkmark32, Edit16 } from '@carbon/icons-react';

export interface Props { 
    selectedObjectID: string | null,
    editingTitle: boolean,
    scene: TimelineScene, 
    index: number, 
    pxWidthPerSecond: number, 
    movingObjectID: string,
    onStartEditingTitle: (sceneID: string, editing?: boolean) => void,
    setSceneTitle: (sceneID: string, title: string) => void,
    onObjectChanging: (sceneID: string, objectID: string) => void,
    setCurrentTime: (time: TimelineCurrentTime) => void,
    dispatch: (action: TimelineAction) => void,
}

export default function TimelineSceneComponent(props: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const dragHandleProps = useContext(TimelineSceneDragHandle);
    const { show } = useContextMenu({ id: "timeline-scene", props: { sceneID: props.scene.id } });

    const onObjectClick = useCallback((objectID: string) => {
        props.dispatch({
            type: TimelineActionType.SetSelectedObject,
            sceneID: props.scene.id,
            objectID,
        });
    }, [props.scene.id]);

    const onObjectChange = useCallback((id: string, x: number, width: number) => {
        props.onObjectChanging(props.scene.id, undefined);
        props.dispatch({
            type: TimelineActionType.ChangeObject,
            sceneID: props.scene.id,
            objectID: id,
            value: { 
                width: width / props.pxWidthPerSecond,
                x: x / props.pxWidthPerSecond,
            },
        });
    }, [props.dispatch, props.scene?.id, props.pxWidthPerSecond]);

    const onClick = useCallback((e: MouseEvent) => {
        let node = e.target as HTMLElement;
        let x = e.clientX;
        while (node) {
            if (node.className?.includes("object")) {
                return;
            }
            x += node.scrollLeft;
            node = node.parentElement;
        }
        x -= ref.current.offsetLeft;
        let sceneCurrentTime = x / props.pxWidthPerSecond;
        props.setCurrentTime({
            sceneID: props.scene.id,
            sceneCurrentTime,
        });
    }, [props.scene.id, props.pxWidthPerSecond]);

    const headerStyles = useMemo(() => (props.scene.thumbnail ? {
        backgroundImage: `url("${props.scene.thumbnail}")`,
    } : {}), [props.scene.thumbnail]);

    const { objects, rows } = updateObjectRows(props.scene.objects);

    const containerStyles = useMemo(() => ({ 
        width: props.scene.duration * props.pxWidthPerSecond,
    }), [rows, props.scene.duration, props.pxWidthPerSecond]);

    const bodyStyles = useMemo(() => ({
        height: rows * (OBJECT_HEIGHT + LAYER_PADDING*2),
    }), [rows]);

    return <div ref={ref} className={styles.container} style={containerStyles} onClick={onClick as any} onContextMenu={show}>
        <div className={styles.header} style={headerStyles} {...dragHandleProps} title={props.scene.title}>
            <LoadingOverlay visible={!props.scene.thumbnail} overlayOpacity={0.01} loaderProps={{ size: "md", variant: "dots" }} />
            {!props.editingTitle ? (<div className={styles.title} data-editing="false">
                <h3>{props.scene.title}</h3>
                <ActionIcon size="lg" variant="hover" onClick={() => props.onStartEditingTitle(props.scene.id)}>
                    <Edit16 />
                </ActionIcon>
            </div>) : (<div className={styles.title} data-editing="true">
                <TextInput value={props.scene.title}
                    onChange={e => props.dispatch({
                        type: TimelineActionType.SetSceneTitle,
                        sceneID: props.scene.id,
                        value: e.target.value,
                    })} />
                <ActionIcon size="xs" variant="hover" onClick={() => props.onStartEditingTitle(props.scene.id, false)}>
                    <Checkmark16 />
                </ActionIcon>
            </div>)}
        </div>
        <TimelineTimestamps start={0}
                            duration={props.scene.duration}
                            pxWidthPerSecond={props.pxWidthPerSecond} />
        <div className={styles.body} style={bodyStyles}>
            {objects.map((o: TimelineSceneObject) => (
                <ResizeableObjectContainer width={o.duration * props.pxWidthPerSecond}
                                           x={o.startTime * props.pxWidthPerSecond}
                                           y={LAYER_PADDING + (OBJECT_HEIGHT+LAYER_PADDING*2) * o.row}
                                           onChanging={() => props.onObjectChanging(props.scene.id, o.id)}
                                           onChange={(x: number, width: number) => onObjectChange(o.id, x, width)}>
                    <TimelineObject 
                        sceneID={props.scene.id}
                        id={o.id} 
                        onClick={e => onObjectClick(o.id)}
                        selected={props.selectedObjectID === o.id}
                        faded={props.movingObjectID && o.id !== props.movingObjectID} />
                </ResizeableObjectContainer>
            ))}
        </div>
    </div>;
}