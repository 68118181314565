import cloneDeep from "clone-deep";
import {EditorPageState, editorPageStateReducer, editorPageStateReducer as uiReducer} from './ui';
import {specReducer} from '../../../core/reducer';
import {stateReducer as timelineReducer} from '../../timeline/reducer';
import { ProjectSpec } from "../../../types/spec";

export interface EditorPageGlobalState {
    ui: EditorPageState,
    spec: ProjectSpec,
}

export function editorPageReducer(previous: EditorPageGlobalState, action: any): EditorPageGlobalState {
    let state = {...previous};

    const type = (action?.type) as string|undefined;

    if (typeof type === 'string') {
        const prefix = type.split(':')[0];
        switch (prefix) {
            case "ui":
                state.ui = editorPageStateReducer(state.ui, action);
                break;
            case "spec":
                state.spec = specReducer(state.spec, action);
                break;
            case "timeline":
                const merged = {
                    scenes: state.spec.scenes,
                    currentTime: state.ui.currentTime,
                    seek: state.ui.seek,
                    selectedObject: state.ui.selectedObject,
                }
                const updated = timelineReducer(merged, action);
                console.log(state, updated);
                state.spec.scenes = updated.scenes;
                state.ui.currentTime = updated.currentTime;
                state.ui.seek = updated.seek;
                state.ui.selectedObject = updated.selectedObject;
                state.spec.modifiedAt = updated.modifiedAt;
                state.spec.changed = state.spec.changed || type?.includes("spec:") || false;
                break;
        }
    }

    if (!state.ui.currentTime) {
        state.ui.currentTime = {
            sceneID: state.spec.scenes[0]?.id,
            sceneCurrentTime: 0,
        }
    }

    // TODO timeline

    // console.log('new state', type, state);

    return state;
}