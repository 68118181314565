import { Video } from "./types";
import { createVideoElement, initializeVideoElement } from "./utils";

interface Callbacks {
    onPrimaryVideoEnded?: () => any,
}

interface VideoManagerPortalConfig {
    primary: Video,
    secondary?: Video,
}

interface VideoManagerPortalElements {
    primary?: HTMLVideoElement,
    secondary?: HTMLVideoElement,
}

interface VideoElement {
    id: string,
    element: HTMLVideoElement,
}

export default class VideoManagerPortal {
    private root: HTMLDivElement | undefined;
    private config: VideoManagerPortalConfig | undefined;
    private elements: VideoManagerPortalElements = {};
    private elementsByID: Record<string, HTMLVideoElement> = {};
    private destroyed = false;

    constructor(private callbacks: Callbacks) {
    }

    public destroy() {
        this.destroyed = true;
        this.config = undefined;

        for (const id of Object.keys(this.elementsByID)) {
            const elem = this.elementsByID[id];
            const source = elem.querySelector("source");

            elem.pause();

            source?.removeAttribute("src");
            source?.remove();

            elem.load();
            elem.remove();

            delete this.elementsByID[id];
        }

        this.elements = {};
        this.elementsByID = {};
        
        this.root?.remove();
        this.root = undefined;
    }

    public async setVideos(config: VideoManagerPortalConfig) {
        if (this.destroyed) {
            throw "Attempt to use destroyed portal.";
        }
        this.config = config;
        await this.update();
    }

    private getPortal() {
        if (this.destroyed) {
            throw "Attempt to use destroyed portal.";
        }
        if (!this.root) {
            console.log("Initializing video portal.");
            this.root = document.createElement("div");
            document.body.appendChild(this.root);
        }
        return this.root as HTMLDivElement;
    }

    private async update() {
        const portal = this.getPortal();

        const primary = this.config?.primary
        const secondary = this.config?.secondary;

        let changed = false;

        this.elements = { primary: undefined, secondary: undefined };

        // remove old videos from DOM
        for (const id of Object.keys(this.elementsByID)) {
            if (primary && primary.id === id) {
                continue;
            }
            if (secondary && secondary.id === id) {
                continue;
            }
            console.log(`Removing video ${id} from DOM.`);

            const elem = this.elementsByID[id];
            const source = elem.querySelector("source");

            elem.pause();

            source?.removeAttribute("src");
            source?.remove();

            elem.load();
            elem.remove();

            delete this.elementsByID[id];

            changed = true;
        }

        if (primary && !this.elementsByID[primary.id]) {
            console.log(`Adding video ${primary.id} to DOM (primary).`);

            const elem = createVideoElement(primary.id, primary.url);
            portal.appendChild(elem);
            await initializeVideoElement(elem);
            this.elementsByID[primary.id] = elem;
            this.elements.primary = elem;

            elem.addEventListener("ended", () => this.onEnded(primary.id));

            changed = true;
        }

        if (secondary && !this.elementsByID[secondary.id]) {
            console.log(`Adding video ${secondary.id} to DOM (secondary).`);

            const elem = createVideoElement(secondary.id, secondary.url);
            portal.appendChild(elem);
            await initializeVideoElement(elem);
            this.elementsByID[secondary.id] = elem;
            this.elements.secondary = elem;

            elem.addEventListener("ended", () => this.onEnded(secondary.id));

            changed = true;
        }

        this.elements = {
            primary: primary ? this.elementsByID[primary.id] : undefined,
            secondary: secondary ? this.elementsByID[secondary.id] : undefined,
        }

        if (changed) {
            console.log("Portal update complete.");
        }
    }

    public primary() {
        return this.elements?.primary;
    }

    public secondary() {
        return this.elements?.secondary;
    }

    public async playPrimary() {
        const elem = this.primary();
        if (elem) {
            await elem.play();
            elem.muted = false;
        }
    }

    public async playSecondary() {
        const elem = this.secondary();
        if (elem) {
            await elem.play();
            elem.muted = false;
        }
    }

    public async pausePrimary() {
        const elem = this.primary();
        if (elem) {
            await elem.pause();
            elem.muted = true;
        }
    }

    public async pauseSecondary() {
        const elem = this.secondary();
        if (elem) {
            await elem.pause();
            elem.muted = true;
        }
    }

    public async pauseAll() {
        await Promise.all([
            this.pausePrimary(),
            this.pauseSecondary(),
        ]);
    }

    private onEnded(id: string) {
        if (this.config?.primary && this.config.primary.id === id) {
            this.callbacks?.onPrimaryVideoEnded?.();
        } else {
            console.log("Ignoring end of video " + id + " (not primary).");
        }
    }
}