import {fabric} from "fabric";
import FabricLayer, {connect} from "@streamloops/overlays/build/fabric-layer";
import {TextOverlayLayer} from "@streamloops/overlays/build/editly-layers/text";
import { ProjectScene, ProjectSceneObject } from "../types/spec";
import canvas from "./video-manager/canvas";

export default class Renderer {
    private layers: Record<string, any> = {};
    private currentScene: ProjectScene;
    private objectCache = new Map<string, fabric.Object>();
    selectedObjectID: string;

    public constructor(public duration: number = 1, public width: number = 0, public height: number = 0) {
    }

    public setCurrentScene(scene: ProjectScene, duration: number, width: number, height: number) {
        this.duration = duration;
        this.currentScene = scene;
        this.width = width;
        this.height = height;
    }

    public renderCurrentScene(time: number, canvas: fabric.Canvas) {
        const progress = time / this.duration;
        
        this.clearObjects(canvas);
        
        this.objectCache.clear();
        
        for (const o of this.currentScene.objects) {
            const createdObject = this.render(o, progress, canvas);
            if (createdObject) {
                createdObject.set('slObjectID', o.id);
                this.objectCache.set(o.id, createdObject);
            }
        }

        if (this.selectedObjectID && this.objectCache.has(this.selectedObjectID)) {
            const selectedObject = this.objectCache.get(this.selectedObjectID);
            canvas.setActiveObject(selectedObject);
        }

        canvas.renderAll();
    }

    public setSelectedObject(objectID: string) {
        this.selectedObjectID = objectID;
    }

    public render(o: ProjectSceneObject, progress: number, canvas: fabric.Canvas) {
        const objectCountBeforeRender = canvas.getObjects().length;

        this.createLayerIfNotExist(o);
        this.layers[o.id]?.setDuration(this.duration);
        this.layers[o.id]?.onRender(progress, canvas);

        const objectCountAfterRender = canvas.getObjects().length;

        if (objectCountAfterRender !== objectCountBeforeRender) {
            const objects = canvas.getObjects();
            return objects[objects.length - 1];
        } else {
            return undefined;
        }
    }

    private createLayerIfNotExist(o: ProjectSceneObject) {
        if (this.layers[o.id]) {
            return;
        }

        let layer: FabricLayer|null = null;

        switch (o.type) {
            case "text":
                layer = new TextOverlayLayer(o.value, o.id, this.duration, 
                    o.startTime, o.duration);
                break;
        }

        if (layer) {
            const connected = connect(layer)({
                width: this.width,
                height: this.height,
                fabric: fabric,
            });

            this.layers[o.id] = connected;
        }
    }

    private clearObjects(canvas: fabric.Canvas) {
        const objects = canvas.getObjects();
        for (let i = 1; i < objects.length; i++) {
            canvas.remove(objects[i]);
        }
    }

    public clearCache() {
        this.layers = {};
    }
}