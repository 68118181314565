import { createContext, useCallback, useRef } from 'react';
import { 
    DragDropContext, 
    Draggable, 
    DraggableProvided, 
    DraggableStateSnapshot, 
    Droppable, 
    DroppableProvided, 
    DroppableStateSnapshot, 
    DropResult 
} from 'react-beautiful-dnd';

import styles from './timeline.module.scss';

export interface TimelineSceneListProps {
    onSceneMove?: (from: number, to: number) => void;
    children?: any,
}

export default function TimelineSceneList(props: TimelineSceneListProps) {
    const onDragEnd = useCallback((dropResult: DropResult) => {
        const from = dropResult.source?.index;
        const to = dropResult.destination?.index || 0;
        props.onSceneMove?.(from, to);
    }, [props.onSceneMove])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"scenes"} type={"scenes"} direction="horizontal" isCombineEnabled={false}>
                {( dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot,) => (  
                    <ul className={styles.scenes} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                        {props.children}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}