import cloneDeep from 'clone-deep';

import { TimelineSceneObject, TimelineState } from "../types";
import { getIndexByID } from "../utils";

export enum TimelineActionType {
    ChangeSceneOrder = "timeline:spec:change-scene-order",
    ChangeObject = "timeline:spec:change-object",
    SetCurrentTime = "timeline:set-current-time",
    SetSelectedObject = "timeline:set-selected-object",
    MoveScene = "timeline:spec:move-scene",
    RemoveScene = "timeline:spec:remove-scene",
    RemoveObject = "timeline:spec:remove-object",
    SetSceneTitle = "timeline:spec:set-scene-title",
}

export interface TimelineAction {
    type: TimelineActionType,
    sceneID?: any,
    objectID?: any,
    value?: any,
}

export function stateReducer(previous: TimelineState, action: TimelineAction): TimelineState {
    const next = {...previous};
    
    switch (action.type) {
        case TimelineActionType.SetSelectedObject:
            if (action.objectID) {
                next.selectedObject = {
                    sceneID: action.sceneID,
                    objectID: action.objectID,
                };
            }
            break;
        case TimelineActionType.ChangeObject:
            const sceneIndex = getIndexByID(next.scenes, action.sceneID);
            const objectIndex = getIndexByID(next.scenes[sceneIndex].objects, action.objectID);

            const object = next.scenes[sceneIndex].objects[objectIndex];

            object.startTime = action.value.x;
            object.duration = action.value.width;

            next.modifiedAt = Date.now();
            break;
        case TimelineActionType.SetCurrentTime:
            next.currentTime = action.value;
            next.seek = action.value;
            break;
        case TimelineActionType.MoveScene:
            {
                const sceneIndex = action.value.from;
                const scene = next.scenes[sceneIndex];
                next.scenes.splice(sceneIndex, 1);
                next.scenes.splice(action.value.to, 0, scene);
                next.modifiedAt = Date.now();
            }
            break;
        case TimelineActionType.SetSceneTitle:
            {
                const sceneIndex = getIndexByID(next.scenes, action.sceneID);
                const scene = next.scenes[sceneIndex];
                scene.title = action.value;
                next.modifiedAt = Date.now();
            }
            break;
        case TimelineActionType.RemoveScene:
            {
                const sceneIndex = getIndexByID(next.scenes, action.sceneID);
                next.scenes.splice(sceneIndex, 1);
                next.modifiedAt = Date.now();

                if (next.currentTime.sceneID === action.sceneID) {
                    // TODO what if there are no scenes?
                    const newSceneIndex = Math.max(0, sceneIndex - 1);
                    next.currentTime = {
                        sceneID: next.scenes[newSceneIndex]?.id,
                        sceneCurrentTime: 0,                        
                    };
                }

                if (next.selectedObject?.sceneID === action.sceneID) {
                    next.selectedObject = undefined;
                }
            }
            break;
        case TimelineActionType.RemoveObject:
            {
                const sceneIndex = getIndexByID(next.scenes, action.sceneID);
                const objectIndex = getIndexByID(next.scenes[sceneIndex].objects, action.objectID);
                next.scenes[sceneIndex].objects.splice(objectIndex, 1);
                next.modifiedAt = Date.now();

                if (next.selectedObject?.sceneID === action.sceneID 
                        && next.selectedObject?.objectID === action.objectID) {
                    next.selectedObject = undefined;
                }
            }
            break;
    }

    return next;
}
